import React from "react";
import ReactPlayer from "react-player";
import "./VideoComparisonGallery.css";

const VideoComparisonGallery = () => {
  const useCases = [
    {
      title: "Text to Video",
      description: "PROMPT: Two wizards locked in a duel in a forest clearing, with glowing spells mid-air. Energy crackles in vibrant blues and reds, lighting up the surrounding trees",
      videos: [
        { src: "https://player.vimeo.com/video/1046656312", label: "Sora" },
        { src: "https://player.vimeo.com/video/1046656512", label: "Runway" },
      ],
    },
    {
      title: "Image to Video",
      description: "PROMPT: A serene forest at sunrise, with golden light streaming through tall trees. A deer stands in a clearing, its fur glowing softly in the morning light as mist swirls around its legs.",
      videos: [
        { src: "https://player.vimeo.com/video/1046569662", label: "Sora" },
        { src: "https://player.vimeo.com/video/1046570318", label: "Runway" },
      ],
    },
    {
      title: "LLM Prompt to Video",
      description: "PROMPT: The camera pans across a rooftop cafe. Guests clink glasses and laugh under glowing string lights, while the city skyline in the background sparkles with activity. A waiter weaves between tables, adding subtle motion.",
      videos: [
        { src: "https://player.vimeo.com/video/1046571433", label: "Sora" },
        { src: "https://player.vimeo.com/video/1046653482", label: "Runway" },
      ],
    },
    {
      title: "Text to Video",
      description: "PROMPT: High-speed car chases in a rugged, dystopian desert. Leather-clad characters ride roaring hot rods, with dynamic camera angles and fast-paced edits. Dramatic lighting and vibrant colors amplify the cinematic thrill, paired with an adrenaline-pumping soundtrack.",
      videos: [
        { src: "https://player.vimeo.com/video/1046655494", label: "Sora" },
        { src: "https://player.vimeo.com/video/1046654758", label: "Runway" },
      ],
    },
  ];

  return (
    <div className="video-gallery-section">
      <header className="video-gallery-header">
        <h1>AI Generated Videos</h1>
        <p className="video-gallery-tagline">
        Welcome to AI Core’s video generation comparison experience. Here, we’ll show you side by side two of the leading tools in the field of video generation: Sora and Runaway. Both are fascinating tools that generate videos from both text and image prompts in just seconds, though the imagination and accuracy varies greatly between the two. Take a look and see which tool aligns with your creative vision.
        </p>
      </header>

      <main className="video-gallery-content">
        {useCases.map((useCase, index) => (
          <section key={index} className="video-gallery-use-case">
            <h2 className="video-gallery-use-case-title">{useCase.title}</h2>
            <p className="video-gallery-description">{useCase.description}</p>
            <div className="video-comparison-row">
              {useCase.videos.map((video, videoIndex) => (
                <div className="comparison-video-wrapper" key={videoIndex}>
                  <p className="video-label">{video.label}</p>
                  <ReactPlayer
                    className="react-player"
                    url={video.src}
                    playing={true}
                    loop={true}
                    muted={true}
                    width="100%"
                    height="100%"
                  />
                </div>
              ))}
            </div>
          </section>
        ))}
      </main>
    </div>
  );
};

export default VideoComparisonGallery;