import React, { useState } from "react";
import axios from "axios";
import topbarLogo from "../images/ua_wordmark.svg";
import Popup from "./Popup";
import "./Sidebar.css";

const Sidebar = ({
  netID,
  setNetID,
  theme,
  setTheme,
  conversations,
  setConversations,
  selectedConversation,
  setSelectedConversation,
}) => {
  const [popup, setPopup] = useState(false);
  const [popupData, setPopupData] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const loginUrl = `${backendUrl}/routes/login`;

  // -----------------------------
  // 1. Sidebar Toggle
  // -----------------------------
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  // -----------------------------
  // 2. Theme Toggle
  // -----------------------------
  const toggleTheme = () => {
    const newTheme = theme === "dark" ? "light" : "dark";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  // -----------------------------
  // 3. Conversation Logic
  // -----------------------------
  // get a random conversation ID for "new chat"
  const generateRandomId = () =>
    Math.floor(Math.random() * (1_000_000 - 10_000 + 1)) + 10_000;

  // 3a. unique conversation IDs
  const uniqueIds = [...new Set(conversations.map((c) => c.id))];

  // 3b. build a short “preview” for each conversation
  const conversationPreviews = uniqueIds.map((id) => {
    const messages = conversations.filter((c) => c.id === id);
    const firstMessage = messages?.[0]?.text || "";
    return { id, firstMessage };
  });

  // create a new conversation
  const handleNewChat = () => {
    if (!netID || netID === "null") return;
    const newID = generateRandomId();
    setSelectedConversation(newID);
    localStorage.setItem("selectedConversation", newID);
  };

  // delete conversation
  const deleteConvo = async (id) => {
    try {
      const resp = await axios.post(
        `${backendUrl}/routes/delete-conversation`,
        { convoToDelete: id },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      if (resp.data) {
        setConversations(resp.data.conversations);
      }
    } catch (error) {
      console.error("Error in deleteConvo:", error);
    }
  };

  // -----------------------------
  // 4. Popup Logic (passcode, etc.)
  // -----------------------------
  const handlePopup = (title, text, button_text = "", button_function = () => {}) => {
    setPopupData({ title, text, button_text, button_function });
    setPopup(true);
  };

  // -----------------------------
  // 5. Render
  // -----------------------------
  return (
    <>
      <Popup popupData={popupData} popup={popup} setPopup={setPopup} setNetID={setNetID} />

      {/* SIDEBAR NAV */}
      <nav className={`sidebar-container ${isSidebarOpen ? "open" : "closed"}`}>
        {/* Sidebar Toggle Button */}
        <button className="toggle-sidebar-button" onClick={toggleSidebar}>
          {isSidebarOpen ? (
            <i className="material-icons">chevron_left</i>
          ) : (
            <i className="material-icons">chevron_right</i>
          )}
        </button>

        {/* Logo Link */}
        <a href="https://www.arizona.edu">
          <img
            className={`sidebar-topbar-logo ${theme === "light" ? "sidebar-invert-logo" : ""}`}
            src={topbarLogo}
            alt="Topbar Logo"
          />
        </a>

        {/* New Chat Button */}
        <div style={{ marginTop: "50px" }} />
        <button disabled={!netID || netID === "null"} className="sidebar-item" onClick={handleNewChat}>
          <i className="material-symbols-outlined" style={{ color: "var(--chat000)", opacity: "0.7" }}>
            edit_square
          </i>
          <div style={{ fontSize: "17px", color: "var(--chat000)" }}>&nbsp;&nbsp;New Chat</div>
        </button>

        {/* Conversation Previews */}
        {conversationPreviews.map(({ id, firstMessage }, idx) => (
          <div
            key={id}
            className={`sidebar-item ${selectedConversation === id ? "sidebar-item-selected" : ""}`}
            onClick={() => {
              setSelectedConversation(id);
              localStorage.setItem("selectedConversation", id);
            }}
          >
            <div style={{ fontSize: "17px", color: "var(--chat000)" }}>
              &nbsp;&nbsp;
              {firstMessage.length > 17 ? firstMessage.substring(0, 17) + "..." : firstMessage}
            </div>
            <i
              className="material-symbols-outlined sidebar-inline-icon-dynamic"
              style={{ color: "var(--chat000)", opacity: "0.7" }}
              onClick={(e) => {
                e.stopPropagation(); // prevent selecting conversation
                deleteConvo(id);
              }}
            >
              delete
            </i>
          </div>
        ))}

        {/* BOTTOM BUTTONS */}
        <div className="sidebar-bottom-buttons">
          {/* Theme Toggle */}
          <div className="sidebar-item" onClick={toggleTheme}>
            <i className="material-symbols-outlined" style={{ color: "var(--chat000)", opacity: "0.7" }}>
              {theme === "dark" ? "bedtime" : "sunny"}
            </i>
            <div style={{ fontSize: "17px", color: "var(--chat000)" }}>&nbsp;&nbsp;Theme</div>
          </div>

          {/* NetID / Login */}
          <a href={!netID || netID === "null" ? loginUrl : "#"}>
            <div className="sidebar-item">
              {!netID || netID === "null" ? (
                <i className="material-symbols-outlined" style={{ color: "var(--chat000)", opacity: "0.7" }}>
                  login
                </i>
              ) : (
                <div className="sidebar-user-profile-circle">
                  {netID.slice(0, 2).toUpperCase()}
                </div>
              )}
              <div style={{ color: "var(--chat000)", fontSize: "17px" }}>
                {netID && netID !== "null" ? netID : "Log in with NetID"}
              </div>
            </div>
          </a>

          {/* Passcode Login if not logged in */}
          {(!netID || netID === "null") && (
            <div className="sidebar-item" onClick={() => handlePopup("Enter passcode", "XXX")}>
              <i className="material-symbols-outlined" style={{ color: "var(--chat000)", opacity: "0.7" }}>
                lock
              </i>
              <div style={{ color: "var(--chat000)", fontSize: "17px" }}>Log in with passcode</div>
            </div>
          )}
        </div>
      </nav>
    </>
  );
};

export default Sidebar;
