import React, { useEffect, useRef, useState } from "react";
import BrainCanvas from "./BrainCanvas";
import RetailXRCanvas from "./RetailXRCanvas";
import HealthXRCanvas from "./HealthXRCanvas";
import ReactPlayer from "react-player";
import VanillaTilt from "vanilla-tilt"; // for interactive tilt
import "../../styles.css";
import "./WildcatWelcome.css";
import dune from "../../images/dune4.webp";

/**
 * Enhanced WildcatWelcome with:
 * - Parallax Hero Section
 * - Tilted Buttons for Interactivity
 * - Retained XR project sections
 */
const WildcatWelcome = () => {
  // Refs for scrolling to each project
  const scienceXR = useRef(null);
  const retailXR = useRef(null);
  const healthXR = useRef(null);

  // Track hovered project buttons
  const [hoveredProject, setHoveredProject] = useState(null);

  // Scroll to the clicked project
  const scrollToClickProject = (name) => {
    let ref = null;
    switch (name) {
      case "Scientific XR Visualization Platform":
        ref = scienceXR;
        break;
      case "Retail Sciences XR/AI Lab":
        ref = retailXR;
        break;
      case "XR for Health Innovation":
        ref = healthXR;
        break;
      default:
        break;
    }

    if (ref !== null) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const projectNames = [
    "Scientific XR Visualization Platform",
    "Retail Sciences XR/AI Lab",
    "XR for Health Innovation",
    "Pima County Visitor's Center NPC AI",
    "Joyful Jobs",
    "Radian",
    "The Play District",
  ];

  /**
   * UseEffect to initialize VanillaTilt on the project buttons
   */
  useEffect(() => {
    const tiltElements = document.querySelectorAll(".tilt-button");
    tiltElements.forEach((el) => {
      VanillaTilt.init(el, {
        max: 10,
        speed: 200,
        glare: true,
        "max-glare": 0.3,
      });
    });
  }, []);

  return (
    <>
      <div className="wildcat-welcome">
        {/* Hero/Parallax Section */}
        <section
          className="hero-section"
          style={{ backgroundImage: `url(${dune})` }}
        >
          <div className="hero-overlay">
            <h1 className="hero-title">Projects</h1>
            <p className="hero-subtitle">
              Highlights from our journey so far
            </p>
            <div className="hero-button-container">
              {projectNames.map((name) => (
                <button
                  key={name}
                  className="tilt-button project-button"
                  onClick={() => scrollToClickProject(name)}
                >
                  {name}
                </button>
              ))}
            </div>
          </div>
          <div className="parallax-scroll-hint">
            <div className="mouse-wheel"></div>
            <span>Scroll down</span>
          </div>
        </section>

        {/* Project Section: Scientific XR Visualization */}
        <section ref={scienceXR} className="section project-section">
          <h2>Scientific XR Visualization Platform</h2>
          <BrainCanvas />
          <p className="project-text">
            Discover the cutting-edge world of interactive brain scans with the
            Scientific XR team. Partnering with the Catalyst Studios at the UA
            Main Library and scientists at the University of Arizona, this
            project brings real scientific data into an immersive 3D environment
            using Unreal Engine. These brain scans, placed in extended reality,
            are ready to be experienced using the Meta Quest 3. Stay tuned for
            more updates!
          </p>
        </section>

        {/* Project Section: Retail Sciences XR/AI Lab */}
        <section ref={retailXR} className="section project-section">
          <h2>Retail Sciences XR/AI Lab</h2>
          <RetailXRCanvas />
          <ReactPlayer
            url="https://vimeo.com/999964422?share=copy"
            playing={true}
            loop={true}
            muted={true}
            className="xr-health"
            width="100%"
            height="100%"
          />
          <p className="project-text">
            Partnering with UArizona Norton School of Human Ecology, this team
            will invent next-generation experiences with Augmented Reality,
            Computer Vision, and Agentic LLM structures in Norton's state-of-the-
            art Retail Sciences Laboratory. A true-to-life retail space,
            complete with product, mood music, and rigged for maximum AI and XR,
            means shopping may never be the same!
          </p>
        </section>

        {/* Project Section: XR for Health Innovation */}
        <section ref={healthXR} className="section project-section">
          <h2>XR for Health Innovation</h2>
          <HealthXRCanvas />
          <ReactPlayer
            url="https://vimeo.com/1000207758?share=copy"
            playing={true}
            loop={true}
            muted={true}
            className="xr-health"
            width="100%"
            height="100%"
          />
          <p className="project-text">
            Led by Professor Matthew Briggs of the University of Arizona Health
            Sciences Design, this team pioneers novel techniques leveraging LLM
            and Computer Vision models to create convincing PBR Textures for
            medical simulation. By harnessing physically based rendering,
            they're forging a "text-to-texture" modality for AI—bringing highly
            realistic avatars and NPCs to life in real-time XR environments.
          </p>
        </section>
      </div>
    </>
  );
};

export default WildcatWelcome;
