import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import FileSaver from "file-saver";
import "./Chat.css";

const Chat = (props) => {
  const {
    netID,
    conversations,
    setConversations,
    selectedConversation,
    setSelectedConversation,
    messagesInConversation,
    setMessagesInConversation,
    GPT,
    threadID,
    setThreadID,
    theme,
    token,
  } = props;

  const [input, setInput] = useState("");
  const messagesEndRef = useRef(null);
  const location = useLocation();
  const [isBotTyping, setIsBotTyping] = useState(false);
  const [fileNames, setFileNames] = useState();

  const navigate = useNavigate();

  // Load current conversation
  useEffect(() => {
    const currentConversation = conversations
      .filter((conversation) => conversation.id === selectedConversation)
      .sort((a, b) => a.SequenceNumber - b.SequenceNumber);

    setMessagesInConversation(currentConversation);
  }, [selectedConversation]);

  // Re-login if needed
  const backend_url = process.env.REACT_APP_BACKEND_URL;
  const reLogin = async () => {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/routes/login`;
  };
  useEffect(() => {
    if (token === "null" || token === null) {
      reLogin();
    }
  }, [netID]);

  // Theme handling
  useEffect(() => {
    document.title = "Chat | AI Core";
    if (location.pathname === "/chat") {
      if (theme === "dark") {
        document.documentElement.style.backgroundColor = "#212121";
        document.body.style.backgroundColor = "#212121";
      } else {
        document.documentElement.style.backgroundColor = "white";
        document.body.style.backgroundColor = "white";
      }
    } else {
      document.body.style.backgroundColor = "white";
    }
    return () => {
      document.body.style.backgroundColor = "white";
    };
  }, [location.pathname, theme]);

  // Auto-scroll to bottom
  const scrollToBottom = () => {
    setTimeout(() => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };
  useEffect(scrollToBottom, [messagesInConversation]);

  // Helper: Safely get nested property
  const getNestedProperty = (obj, path) =>
    path.split(".").reduce((acc, key) => acc && acc[key], obj);

  // Specialized stable diffusion handling
  const processStableDiffusionResponse = (response, messagesToSend) => {
    const base64Image = response.data.message;
    const byteChars = atob(base64Image);
    const byteNums = Array.from(byteChars).map((ch) => ch.charCodeAt(0));
    const blob = new Blob([new Uint8Array(byteNums)], { type: "image/png" });
    const objectURL = URL.createObjectURL(blob);

    const updated = [
      ...messagesToSend,
      {
        text: objectURL,
        sender: "bot",
        selectedModel: response.data.selectedModel,
      },
    ];
    setMessagesInConversation(updated);
  };

  // Model configuration (only GPT-4o, Claude, Gemini, Stable Diffusion)
  const modelConfigs = {
    "GPT-4o": {
      endpoint: "/routes/message-gpt4",
      senderKey: "assistant",
      responseMessageKey: "message",
    },
    "Claude (Sonnet)": {
      endpoint: "/routes/message-claude",
      senderKey: "assistant",
      responseMessageKey: "message",
    },
    "Gemini 1.5 Pro": {
      endpoint: "/routes/message-gemini",
      senderKey: "bot",
      responseMessageKey: "message",
    },
    "Stable Diffusion": {
      endpoint: "/routes/message-stable-diffusion",
      senderKey: "bot",
      responseMessageKey: "message",
      processResponse: processStableDiffusionResponse,
    },
  };

  // Send message
  const sendMessageToModel = async (e) => {
    e.preventDefault();
    if (input.trim() === "") return;

    const newMessages = [
      ...messagesInConversation,
      { text: input, sender: "user" },
    ];
    setMessagesInConversation(newMessages);
    setInput("");
    await new Promise((resolve) => setTimeout(resolve, 500));
    setIsBotTyping(true);

    try {
      // Model config
      const modelConfig = modelConfigs[GPT] || {};
      const endpoint = modelConfig.endpoint || "/default-endpoint";
      const senderKey = modelConfig.senderKey || "bot";
      const responseMessageKey = modelConfig.responseMessageKey || "message";
      const processResponse = modelConfig.processResponse;

      // Construct data to send
      const dataToSend = {
        messages: newMessages,
        selectedConversation,
        selectedModel: GPT,
        threadID,
      };

      // Make API request
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}${endpoint}`,
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setIsBotTyping(false);

      // If there's a specialized processResponse (Stable Diffusion)
      if (processResponse) {
        processResponse(response, newMessages);
      } else {
        const responseData = response.data;
        const responseMessage =
          getNestedProperty(responseData, responseMessageKey) || "";

        const updatedMessages = [
          ...newMessages,
          {
            text: responseMessage,
            sender: senderKey,
            selectedModel: responseData.selectedModel,
          },
        ];
        setMessagesInConversation(updatedMessages);

        if (responseData.threadID && responseData.threadID !== threadID) {
          setThreadID(responseData.threadID);
        }
      }
    } catch (error) {
      console.error("Error in sendMessageToModel:", error);
      setIsBotTyping(false);
    }
  };

  // On pressing the send button or Enter
  const handleMessageSend = (event) => {
    sendMessageToModel(event);
  };

  // Handle image download
  const handleImageDownload = (imageUrl) => {
    const filename = imageUrl.split("/").pop() || "image.png";
    FileSaver.saveAs(imageUrl, filename);
  };

  // Return an avatar for each model
  const getImagePath = (selectedModel) => {
    switch (selectedModel) {
      case "GPT-4o":
        return require("../images/gpt4.png");
      case "Claude (Sonnet)":
        return require("../images/claude.jpg");
      case "Gemini 1.5 Pro":
        return require("../images/gemini.jpg");
      case "Stable Diffusion":
        return require("../images/stability.jpg");
      default:
        return require("../images/default.webp");
    }
  };

  return (
    <>
      {selectedConversation ? (
        <div className="chat-wrapper-chatbot">
          <div className="chat-container">
            <div className="chat-messages-container">
              {messagesInConversation
                ?.filter((message) => message.text !== "NULL")
                .map((message, index) => {
                  // Determine alignment
                  const isBotLike =
                    message.sender === "assistant" ||
                    message.sender === "bot" ||
                    message.sender.startsWith?.("bot");
                  const alignment = isBotLike
                    ? "chat-message-container-left"
                    : "chat-message-container-right";

                  return (
                    <div key={index}>
                      <div className={`chat-message-container ${alignment}`}>
                        {/* If message is from "bot"/"assistant", show model avatar */}
                        {isBotLike && (
                          <img
                            className="chat-bot-profile-pic"
                            src={getImagePath(message.selectedModel)}
                            alt="Avatar"
                          />
                        )}
                        <div className="chat-message-subcontainer">
                          <div
                            className={`chat-message-bubble ${
                              message.sender === "user"
                                ? "chat-message-sender"
                                : ""
                            } ${
                              message.text?.startsWith("https://oaidalleapiprodscus")
                                ? "chat-is-image"
                                : ""
                            }`}
                          >
                            {/* If it's a generated image from DALL·E or stable diffusion */}
                            {message.text?.startsWith("https://oaidalleapiprodscus") ? (
                              <div className="chat-image-with-button">
                                <img
                                  src={message.text}
                                  alt="Generated"
                                  className="chat-generated-image"
                                />
                                <button
                                  className="chat-download-button"
                                  onClick={() => handleImageDownload(message.text)}
                                >
                                  <i className="material-symbols-outlined chat-invert-icon">
                                    download
                                  </i>
                                </button>
                              </div>
                            ) : message.selectedModel === "Stable Diffusion" ? (
                              <img
                                className="chat-stable-diffusion-image"
                                src={message.text}
                                alt=""
                              />
                            ) : (
                              /* Standard text message */
                              <div className="chat-individual-message-bot">
                                {message.text}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {isBotTyping && (
                <div className="chat-message-container chat-message-container-left">
                  <img
                    className="chat-bot-profile-pic"
                    src={getImagePath(GPT)}
                    alt="Bot typing"
                  />
                  <div className="chat-message-bubble">
                    <div className="chat-typing-animation">
                      <div className="chat-dot"></div>
                      <div className="chat-dot"></div>
                      <div className="chat-dot"></div>
                    </div>
                  </div>
                </div>
              )}
              <div ref={messagesEndRef}></div>
            </div>
          </div>
          <br />
          {/* Chat Input */}
          <div className="chat-input-container">
            <div className="chat-input-wrapper">
              <TextareaAutosize
                name="input"
                className="chat-input-box"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder={`Message ${GPT}...`}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    handleMessageSend(e);
                  }
                }}
              />
              <button
                type="submit"
                className="chat-send-button"
                disabled={!input.trim()}
                onClick={handleMessageSend}
              >
                <i className="material-symbols-outlined chat-send-icon" style={{ color: "var(--chat900)" }}>
                  arrow_upward
                </i>
              </button>
            </div>
            <div className="chat-powered-by">
              Powered by AI Core at the University of Arizona.
            </div>
          </div>
        </div>
      ) : (
        <div className="chat-welcome-container">
          <div className="chat-welcome-message">
            Welcome!
            <br />
            <br />
            {!netID || netID === "null"
              ? "Log in with NetID or passcode to get started."
              : "Create a chat or select a conversation to get started."}
          </div>
        </div>
      )}
    </>
  );
};

export default Chat;
